<template>
  <div class="app">
    <div class="content">
      <ul>
        <li
          :class="current == index ? 'active' : ''"
          v-for="(item, index) in menuList"
          :key="item.id"
          @click="changeMenu(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      menuList: [
        { id: 0, name: "首页" },
        { id: 1, name: "产品中心" },
        { id: 2, name: "关于我们" },
        { id: 3, name: "联系我们" },
      ],
    };
  },
  mounted() {
    this.current = this.$route.name;
  },
  methods: {
    changeMenu(param) {
      if (this.current != param) {
        this.current=param
        if (param == 0) {
          this.$router.push("/");
        } else if (param == 1) {
          this.$router.push("/product");
        } else if (param == 2) {
          this.$router.push("/aboutUs");
        } else if (param == 3) {
          this.$router.push("/contactUs");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.app {
  background: #1e50ae;
  .content {
    width: 1200px;
    margin: 0 auto;
    ul {
      display: flex;
      li {
        line-height: 50px;
        text-align: center;
        width: 100px;
        color: #ffffff;
        margin-right: 5px;
        &:hover {
          background: #13439d;
        }
        &.active {
          background: #13439d;
        }
      }
    }
  }
}
</style>